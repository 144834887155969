body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active-link {
  background-color: brown;
}

.link {
  color: #ffffff;
  text-decoration: none;
}

.link-text {
  color: #ffffff;
  text-decoration: none;
  padding: 10px;
  background-color: transparent;
}

.large-icon {
  font-size: 1.5rem;
}

.modal-fullscreen {
  width: 40% !important;
  right: 0;
  position: absolute !important;
}

.customCard {
  background: #d7cbba !important;
}

.smallerFont {
  font-size: smaller;
}

.smallFont {
  font-size: small;
}

.xSmallFont {
  font-size: x-small;
}

.noUnderline {
  text-decoration: none !important;
}